<template>
  <div id="ServiceType">
    <strong>Select The Service That Best Fits Your Needs</strong>
    <div>
      <SingleChoiceField
        v-model="selectedType"
        :options="searchOptions"
        :options-per-line="1"
        class="col q-mb-md option-wrapper services"
      />
      <BaseButton
        label="Get Started"
        class="get-started-btn"
        :is-loading="isLoading"
        @click="createNewAccount"
      />
    </div>
  </div>
</template>

<script>
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";

export default {
  name: "ServiceType",

  components: { SingleChoiceField },

  props: {
    value: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPasswword: "",
      selectedType: 1,
      searchOptions: [
        {
          id: this.$nano.id(),
          label: "DMS-Manage and store all your documents in one secure place.",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label:
            "Workflow - Automated your bussiness process for better efficiency.",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label:
            "DMS + Workflow - Combine the power of DMS and Workflow for total bussiness integrtion.",
          value: 3,
        },
      ],
    };
  },

  methods: {
    createNewAccount() {
      this.$emit("createNewAcount", this.selectedType);
    },
  },
};
</script>

<style lang="scss" scoped>
#ServiceType {
  .get-started-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 8px;
  }
}
</style>
<style>
#single-choice-field.services .options-wrapper {
  border: none !important;
}
</style>
