<template>
  <AuthLayout>
    <div id="sign-up">
      <div v-if="!newAccountCreated" class="description title">
        “Transform Your Business with EZOFIS: Seamlessly Automate, Innovate, and
        Elevate!”
      </div>
      <div v-else class="description col">
        <div class="row">
          <div class="col-2" style="margin: auto">
            <BaseActionButton
              color="secondary"
              is-flat
              size="30px"
              icon="mdi-check"
              class="mdi-checks"
            />
          </div>
          <div class="col-10" style="margin: auto">
            <div>Thank you for verifying your account.</div>
            <div style="margin-top: 10px">
              Please choose the module you plan to use, to complete the account
              creation.
            </div>
          </div>
        </div>
      </div>

      <div class="quote">“</div>
      <template v-if="isNotSocialSignUp">
        <div v-if="!isSignUpWithEmail">
          <SocialAuths
            logged-from="WEB"
            :social-login="checkTenantSocialLogin"
            class="social-auths"
            :sign-up="socialAuthSignUP"
            @logged="socialAuth"
          />
          <div class="or-section"><span>(or)</span></div>
          <div class="sign-up-email-contianer">
            <BaseButton
              label="Sign up with Email"
              class="sign-up-email"
              transform=""
              @click="signUpWithEmail"
            />
          </div>
        </div>

        <div v-else-if="!createAccount">
          <ValidationObserver ref="form">
            <div v-if="!otpVerify">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email"
                :rules="{ required: true, email: true }"
              >
                <TextField
                  v-model="email"
                  is-mandatory
                  label="Email"
                  class="q-mb-md"
                  :is-readonly="isLoading"
                  :error="errors[0]"
                  @enter="getVerification"
                />
              </ValidationProvider>
            </div>

            <div v-else class="email-text">
              Verificaion code sent to
              <strong style="color: #18b5dd">{{ email }}</strong>
            </div>
            <div v-if="!otpVerify">
              <ValidationProvider
                v-slot="{ errors }"
                name="checkbox"
                :rules="{ required: true }"
              >
                <div class="row">
                  <CheckboxField
                    v-model="agree"
                    description=""
                    class="q-mb-md col-1 agree-checkbox"
                  />
                  <div class="text-sm col-11 description-agree">
                    <span @click="agree = !agree"
                      >By clicking 'I agree to our
                      <a
                        href="https://ezofis.com/privacy-policy/"
                        target="_blank"
                        class="link"
                        @click.stop
                        >Terms of Service
                      </a>
                      &
                      <a
                        href="https://ezofis.com/privacy-policy/"
                        target="_blank"
                        class="link"
                        @click.stop
                      >
                        Privacy Policy
                      </a>
                      ,' you consent to abide by our terms and conditions as
                      well as our privacy practices.
                    </span>
                  </div>
                </div>
                <div
                  class="text-sm q-mb-md"
                  style="color: red; margin-top: 8px"
                >
                  {{
                    errors[0]
                      ? "Please agree the terms and conditions to proceed."
                      : ""
                  }}
                </div>
              </ValidationProvider>
            </div>
          </ValidationObserver>
          <BaseButton
            v-if="!otpVerify"
            label="Generate Verification Code"
            color="secondary"
            :is-loading="mailVerification"
            class="generate-verfication-btn"
            @click="getVerification"
          />
          <OTPField
            v-if="otpVerify"
            otp-type="email"
            :otp-verify="otpVerify"
            :clock-tick="clockTick"
            :sign-up="true"
            @validate="OTPValidate"
          ></OTPField>
        </div>

        <div v-if="createAccount && !newAccountCreated">
          <CreateAccount :email="email" @getUserDetails="getUserDetails" />
        </div>
      </template>
      <div v-if="newAccountCreated">
        <ServiceType :is-loading="isLoading" @createNewAcount="createNewUser" />
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import SocialAuths from "../sign-in/components/SocialAuths.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CreateAccount from "./components/CreateAccount.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import OTPField from "@/components/common/form/otp-field/OTPField.vue";
import ServiceType from "./components/ServiceType.vue";
import { auth } from "@/api/factory.js";
// import store from "@/store/index.js";profile
// import * as Msal from "msal";

export default {
  name: "Signup",

  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider,
    TextField,
    CheckboxField,
    SocialAuths,
    CreateAccount,
    OTPField,
    ServiceType,
  },

  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      isLoading: false,
      error: "",
      normalLogin: false,
      tenantList: [],
      showTenantListModal: false,
      tenant: "",
      isSignUpWithEmail: false,
      agree: false,
      otpVerify: false,
      clockTick: false,
      createAccount: false,
      newAccountCreated: false,
      isNotSocialSignUp: true,
      mailVerification: false,
      userDetails: {},
      loginType: "EZOFIS",
      socialAuthSignUP: true,
    };
  },

  computed: {
    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return true;
      }
      return false;
    },

    checkTenantLogin() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },

    checkTenantSocialLogin() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      }
      return true;
    },

    checkAdlogin() {
      let origin = location.origin;
      if (origin === "http://172.16.1.118") {
        return true;
      }
      return false;
    },

    checkForgot() {
      let origin = location.origin;
      if (origin === "http://localhost:8080") {
        return true;
      }
      return false;
    },
  },

  methods: {
    async createNewUser(type) {
      let input = {
        ...this.userDetails,
        licenseType: type,
        email: this.email,
        loginType: this.loginType,
      };
      this.isLoading = true;
      const { error, data } = await auth.signUp(input);
      console.log(error, data, "error, data ");
      if (error) {
        this.$alert.info(`Faild to signup`);
        this.isLoading = false;
      }
      if (data) {
        this.isLoading = false;
        this.$alert.success(`Account created successfully`);
        this.login();
        // this.otpVerify = true;
        // this.clockTick = true;
        // this.mailVerification = false;
      }
    },

    async login() {
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        this.$router.push({ name: "workflows-inbox" });
        return;
      }
      this.$router.push({ name: "repositories-overview" });
    },

    getUserDetails(object) {
      this.userDetails = object;
      this.newAccountCreated = true;
    },

    async OTPValidate(OTPNumbers) {
      if (OTPNumbers == "NewAccount") {
        this.otpVerify = false;
        this.clockTick = false;

        this.email = "";
        return;
      } else if (OTPNumbers == "Resend") {
        this.getVerification();
        return;
      }
      OTPNumbers = OTPNumbers.join("");
      if (OTPNumbers.length !== 6) {
        return;
      }

      let input = {
        email: this.email,
        otp: OTPNumbers,
      };
      const { error, data } = await auth.verifyMailOTP(input);
      if (error) {
        this.$alert.info(`email not verified`);
      }
      if (data) {
        this.otpVerify = false;
        this.createAccount = true;
        this.$alert.success(`Email verified`);
      }
    },

    async getVerification() {
      const areAllFieldsValid = await this.$refs.form.validate();

      console.log(areAllFieldsValid);
      if (!areAllFieldsValid) {
        return;
      }
      let input = {
        email: this.email,
        requiredOTP: true,
      };
      this.mailVerification = true;
      const { error, data } = await auth.sendMailOTP(input);
      console.log(error, data);
      if (error) {
        console.log(error);
        if (
          error ==
          "Tenant is already exists, Please change the Email for signup"
        ) {
          this.$alert.info(
            `Email already exists, Please change the Email for signup`
          );
        } else {
          this.$alert.info(`email not valid`);
        }

        this.mailVerification = false;
      }
      if (data) {
        this.$alert.success(`OTP sent to registered email`);
        this.otpVerify = true;
        this.clockTick = true;
        this.mailVerification = false;
      }
    },

    signUpWithEmail() {
      this.isSignUpWithEmail = true;
    },

    async socialAuth(type, value) {
      if (type === "GOOGLE") {
        let input = {
          email: value[5],
          requiredOTP: false,
        };
        this.mailVerification = true;
        const { error, data } = await auth.sendMailOTP(input);
        console.log(error, data);
        if (error) {
          this.mailVerification = false;
          this.$alert.info(
            `Email already exists, Please change the Email for signup`
          );
          return;
        }
        this.userDetails = {
          firstName: value[2],
          lastName: value[3],
          password: "",
          organisation: "",
        };
        this.email = value[5];
      } else if (type === "MICROSOFT") {
        let input = {
          email: value.userName,
          requiredOTP: false,
        };
        this.mailVerification = true;
        const { error, data } = await auth.sendMailOTP(input);
        console.log(error, data);
        if (error) {
          this.mailVerification = false;
          this.$alert.info(
            `Email already exists, Please change the Email for signup`
          );
          return;
        }
        let fullName = value.name;
        let nameParts = fullName.split(" ");
        let firstName = nameParts[0];
        let remainingName = nameParts.slice(1, -1).join(" ");

        this.userDetails = {
          firstName: firstName,
          lastName: remainingName,
          password: "",
          organisation: "",
        };
        this.email = value.userName;
      }
      this.mailVerification = false;
      this.isNotSocialSignUp = false;
      this.newAccountCreated = true;
      this.loginType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
#sign-up {
  display: flex;
  flex-direction: column;
  .email-text {
    margin-bottom: 5px;
    color: var(--title-1-color);
  }
  .description {
    font-family: "Outfit";

    border-bottom: 1px solid #cfd5dc;
    padding-bottom: 23px;
    margin-bottom: 10px;
    color: black;
  }
  .link {
    text-decoration: none;
    color: #00bfd6;
  }
  .description-agree {
    color: var(--icon-color-inverted);
    cursor: pointer;
  }
  .agree-checkbox {
    margin: auto;
  }

  .title {
    text-align: center;
    font-size: 16px;
    font-style: italic;
  }
  .quote {
    font-size: 62px;
    color: #cdd5dc;
    font-style: italic;
    position: relative;
    top: -7px;
    height: 5px;
    text-align: center;
  }

  .title {
    @extend .text-lg;
    font-weight: bold;
    color: var(--title-1-color);
    margin-bottom: 12px;
  }

  .sign-in-btn,
  .generate-verfication-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 8px;
  }
  .mdi-checks {
    border: 1px solid rgb(0 191 214);
    margin: auto;
  }
}

.tenant {
  cursor: pointer;
  // border-bottom: 1px solid #eee;
}
.tenant:hover {
  cursor: pointer;
  background: #eee;
}

.colse {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.or-section {
  display: flex;
  // align-items: center;
  justify-content: center;
  color: #cdd5dc;
  margin: 15px 0px 15px 0px;
}
// .or-section span {
//     margin: 0 10px;
// }
.or-section::before,
.or-section::after {
  content: "............................................";
  flex: 1;
  height: 1px;
  margin: -3px 10px;
}
.social-auths {
  width: 100%;
}
.sign-up-email {
  min-height: 44px !important;
  width: 100%;
  margin-top: 8px;
}
</style>
