<template>
  <div id="forgot-password">Forgot password?</div>
</template>

<script>
export default {
  name: "ForgotPassword",
};
</script>

<style lang="scss" scoped>
#forgot-password {
  color: var(--secondary);
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}
</style>
