<template>
  <div id="createAccount">
    <div class="email-text q-mb-md">
      <div class="row">
        <div class="col-auto">
          <BaseIcon
            name="eva-checkmark-circle-2"
            color="positive"
            class="q-mr-sm"
          />
        </div>
        <div class="col" style="margin-top: 1px">
          Email verified
          <span class="text-secondary text-bold">{{ email }}</span>
        </div>
      </div>
    </div>
    <ValidationObserver ref="form">
      <div class="row">
        <div class="col-6 q-pr-sm">
          <ValidationProvider
            v-slot="{ errors }"
            name="First Name"
            :rules="{ required: true }"
          >
            <TextField
              v-model="firstName"
              label="First Name"
              is-mandatory
              :auto-focus="true"
              :is-readonly="isLoading"
              :error="errors[0]"
              class="q-mb-md"
              @enter="$emit('enter')"
            />
          </ValidationProvider>
        </div>

        <div class="col-6 q-pl-sm">
          <ValidationProvider
            v-slot="{ errors }"
            name="Last Name"
            :rules="{ required: true }"
          >
            <TextField
              v-model="lastName"
              label="Last Name"
              is-mandatory
              :auto-focus="true"
              :is-readonly="isLoading"
              :error="errors[0]"
              class="q-mb-md"
              @enter="$emit('enter')"
            />
          </ValidationProvider>
        </div>

        <div class="col-6 q-pr-sm">
          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="password"
              label="password"
              is-mandatory
              :tooltip="_passwordHint"
              :error="errors[0]"
              @enter="$emit('enter')"
            />
          </ValidationProvider>
        </div>

        <div class="col-6 q-pl-sm">
          <PasswordField
            v-model="confirmPassword"
            label="confirm password"
            is-mandatory
            :is-readonly="isLoading"
            @input="checkConfirmPassword"
            @enter="$emit('enter')"
          />
          <FormFieldError
            v-if="confirmPasswordError"
            :error="confirmPasswordError"
          />
        </div>

        <div class="col-12 q-mt-md">
          <ValidationProvider
            v-slot="{ errors }"
            name="Organization"
            :rules="{ required: true }"
          >
            <TextField
              v-model="organization"
              label="Organization"
              is-mandatory
              :auto-focus="true"
              :is-readonly="isLoading"
              :error="errors[0]"
              class="q-mb-md"
              @enter="$emit('enter')"
            />
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <BaseButton
      label="Create Account"
      class="sign-up-btn"
      @click="createNewAccount"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";

import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import { passwordHint } from "@/helpers/password-hint.js";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "CreateAccount",

  components: {
    ValidationProvider,
    ValidationObserver,
    PasswordField,
    TextField,
    FormFieldError,
  },

  props: {
    email: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      organization: "",
      confirmPasswordError: "",
    };
  },

  computed: {
    _passwordHint() {
      return passwordHint;
    },
  },
  methods: {
    checkConfirmPassword() {
      this.confirmPasswordError = "";
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordError = "Password not matched";
      }
    },

    async createNewAccount() {
      const areAllFieldsValid = await this.$refs.form.validate();
      console.log(areAllFieldsValid);
      if (!areAllFieldsValid) {
        return;
      }
      if (this.password === this.confirmPassword) {
        let details = {
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          organisation: this.organization,
        };
        this.$emit("getUserDetails", details);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#createAccount {
  .email-text {
    color: var(--title-1-color);
  }

  .sign-up-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 8px;
  }
}
</style>
