<template>
  <div id="remember-me">
    <BaseActionButton
      is-flat
      no-border
      :color="value ? 'secondary' : 'gray'"
      :icon="value ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
      @click="$emit('input', !value)"
    />

    <div class="label" @click="$emit('input', !value)">Remember me</div>
  </div>
</template>

<script>
export default {
  name: "RememberMe",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#remember-me {
  display: flex;
  align-items: center;

  .label {
    color: var(--icon-color);
    margin-left: 8px;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
